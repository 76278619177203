<script lang="ts">

  
  
  
  
  
  
  
  interface Props {
    class?: string;
    circle?: boolean;
    // src should be a default img src like jpg with pixel density of 1
    src: string;
    // srcset should at least be the 2x img version for retina screens
    srcset?: string | null;
    srcsetWebp?: string | null;
    // sizes should be the width the image takes up in the DOM
    sizes?: string | null;
    // alt should be the alt text for the image
    alt?: string;
    // loading is "lazy" by default, but above the fold images can be "eager"
    loading?: "eager" | "lazy";
    // decoding should rarely be anything other than "async"
    decoding?: "async" | "auto" | "sync" | null;
    // img dimensions of src
    height?: number | null;
    width?: number | null;
  }

  let {
    class: classes = "",
    circle = false,
    src,
    srcset = null,
    srcsetWebp = null,
    sizes = null,
    alt = "",
    loading = "lazy",
    decoding = null,
    height = null,
    width = null
  }: Props = $props();
  // img classes
  

  // export let fetchpriority: string | null = null;
</script>

{#if srcsetWebp}
  <picture>
    <source type="image/webp" srcset={srcsetWebp} {sizes} />
    <img
      {src}
      {srcset}
      {sizes}
      {alt}
      {loading}
      decoding={decoding ? decoding : loading === "lazy" ? "async" : undefined}
      {height}
      {width}
      class="picture-component-img-element {classes}"
      class:circle
    />
  </picture>
{:else}
  <img
    {src}
    {srcset}
    {sizes}
    {alt}
    {loading}
    decoding={decoding ? decoding : loading === "lazy" ? "async" : undefined}
    {height}
    {width}
    class="picture-component-img-element {classes}"
    class:circle
  />
{/if}

<style lang="postcss">
  /* TODO: this is global till Unocss classes is in top `@layer` */
  :global(:where(.picture-component-img-element)) {
    aspect-ratio: var(--picture-aspect-ratio, unset);
    border-radius: var(--rounded-picture);
    object-fit: var(--picture-object-fit, unset);
    width: var(--picture-width, unset);
    height: var(--picture-height, auto); /* `auto` since global `img` has `max-width: 100%` */
    min-width: auto;
    min-height: auto;
  }

  img.circle {
    aspect-ratio: var(--picture-aspect-ratio, 1 / 1);
    clip-path: circle(50%);
    object-fit: cover;
  }
</style>
