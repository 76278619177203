<script lang="ts">
  import type { SanityPicture } from "../lib/types/shared";
  import Picture from "./Picture.svelte";


  
  
  
  
  
  interface Props {
    class?: string;
    pictureObject: SanityPicture;
    circle?: boolean;
    // alt should be the alt text for the image
    alt?: string;
    // sizes should be the width the image takes up in the DOM
    sizes?: string | null;
    // loading is "lazy" by default, but above the fold images can be "eager"
    loading?: "eager" | "lazy";
    // decoding should rarely be anything other than "async"
    decoding?: "async" | "auto" | "sync" | null;
    // width, height
    width?: number | null;
    height?: number | null;
  }

  let {
    class: classes = "",
    pictureObject,
    circle = false,
    alt = "",
    sizes = null,
    loading = "lazy",
    decoding = null,
    width = null,
    height = null
  }: Props = $props();
  // img classes
  

  let widthValue = $derived(width || pictureObject.width || pictureObject.asset?.metadata?.dimensions?.width);
  let heightValue =
    $derived(height || pictureObject.height || pictureObject.asset?.metadata?.dimensions?.height);
</script>

{#if pictureObject.src}
  <Picture
    src={pictureObject.src}
    srcset={pictureObject.srcset}
    srcsetWebp={pictureObject.srcsetWebp}
    alt={alt || pictureObject.alt || ""}
    width={widthValue}
    height={heightValue}
    {sizes}
    {loading}
    {decoding}
    {circle}
    class={classes}
  />
{/if}
